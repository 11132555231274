import { useState } from "react";
import { connect } from "react-redux";

import { ProjectUrl } from "~/env";

import { Card } from "@bit/healthmug.admin.card";
import { Input } from "@bit/healthmug.admin.input";
import { Button } from "@bit/healthmug.admin.button";

import { useLoader } from "~/actions/custom-hooks";
import { ForgotPasswordValidation } from "~/actions/forgot-password-action";
import { LoginSetEmailOrPassword, LoginOnFocus, LoginCheckValidation } from "~/actions/login-action";

const LoginScreen = (props) => {
	const [loader, startLoader, endLoader] = useLoader(false);
	const [message, set_message] = useState(null);

	const Login = () => {
		startLoader();
		props
			.Login_Check_Validation()
			.then((data) => {
				data ? (window.location.href = `${ProjectUrl}/analytics/dashboard`) : null;
			})
			.catch(() => null)
			.finally(endLoader);
	};

	const forgot_password = () => {
		props.Forgot_Password_Validation(props.email).then(() => set_message("Reset password link has been send to your email"));
	};

	return (
		<div>
			<Card>
				<div className="uk-container">
					<div className="uk-grid">
						<div className="uk-width-expand">
							<div className="loginLogo">
								<img src="https://static.oxinis.com/seller/image/layout/logo.png" />
							</div>
						</div>
						<div className="uk-width-auto@s" style={{ marginBottom: "-10px" }}>
							<div className="uk-grid uk-grid-column-small uk-margin-small-bottom uk-visible@s ">
								<div className="uk-width-expand">{message ? <p className="uk-text-danger">{message}</p> : null}</div>
								<div>
									<a onClick={forgot_password}>Forgot Password</a>
								</div>
							</div>
							<div className="uk-grid uk-grid-column-collapse uk-margin-remove-top@s uk-margin-remove-bottom@s uk-margin-medium-bottom">
								<div className="uk-width-expand@s uk-margin-small-left uk-margin-small-left uk-margin-small-right uk-margin-remove-right@s">
									<Input
										name="email"
										onFocus={() => props.Login_On_Focus("email")}
										small
										value={props.email}
										onChange={(e) => props.Login_Set_Email_Or_Password(e.target.name, e.target.value)}
										placeholder="User ID"
										error={props.error_list.email ? props.error_list.email : null}
										required
									/>
								</div>
								<div className="uk-width-expand@s uk-margin-small-left uk-margin-small-bottom uk-margin-small-right uk-margin-remove-right@s">
									<Input
										name="password"
										onKeyPress={(e) => (e.charCode == 13 ? Login() : null)}
										onChange={(e) => props.Login_Set_Email_Or_Password(e.target.name, e.target.value)}
										placeholder="Password"
										type="password"
										value={props.password}
										onFocus={() => props.Login_On_Focus("password")}
										small
										required
									/>
									<div className="brandTextDanger smFont" style={{ position: "absolute", top: "58px" }}>
										{props.error_list.password ? props.error_list.password : null}
									</div>
									<br />
								</div>

								<div className="uk-width-auto@s uk-margin-small-left uk-margin-small-right uk-margin-remove-right@s">
									<Button onClick={Login} loader={loader} disabled={loader} warning small className="uk-width-1-1">
										Login
									</Button>
								</div>
								<div className="uk-width-expand uk-hidden@s uk-margin-small-left uk-margin-small-right uk-margin-small-top uk-text-right">
									<a onClick={forgot_password}>Forgot Password</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Card>
			<div className="bodyWrap">
				<div className="banner">
					<div className="uk-container">
						<div className="row">
							<div className="col-xs-12 bannerContent">
								<div className="bannerContentInner">
									<div className="subHeading">Expand your market place, sell on</div>
									<div className="mainHeading">Healthmug.com</div>
									<div className="tagline">Biggest online store for Ayurvedic & Homeopathy medicine</div>
									<div className="keyPoint">
										<p>
											<i uk-icon="icon:check;ratio:0.7" /> Reach to large number of customer
										</p>
										<p>
											<i uk-icon="icon:check;ratio:0.7" /> Quick Payment
										</p>
										<p>
											<i uk-icon="icon:check;ratio:0.7" /> Easy to make Product Listing
										</p>
										<p>
											<i uk-icon="icon:check;ratio:0.7" /> Supportive Customer care
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="quickLinkWrap">
					<div className="uk-container">
						<div className="uk-grid uk-grid-column-small uk-child-width-1-3@s">
							<div>
								<div className="quickLink">
									<div className="quickLinkIcon">
										<img src="https://static.oxinis.com/seller/image/layout/sell-24.jpg" />
									</div>
									<div className="quickLinkTitle">Sell 24X7</div>
									<div className="quickLinkTag">Million of User & Many trusted Seller</div>
								</div>
							</div>
							<div>
								<div className="quickLink">
									<div className="quickLinkIcon">
										<img src="https://static.oxinis.com/seller/image/layout/quick-payment.jpg" />
									</div>
									<div className="quickLinkTitle">Quick Payment</div>
									<div className="quickLinkTag">Quick &amp; Transparent process</div>
								</div>
							</div>
							<div>
								<div className="quickLink">
									<div className="quickLinkIcon">
										<img src="https://static.oxinis.com/seller/image/layout/seller-support.jpg" />
									</div>
									<div className="quickLinkTitle">Seller Support</div>
									<div className="quickLinkTag">Strong seller support system</div>
								</div>
							</div>
						</div>
						<hr />
					</div>
				</div>

				<div className="stepToSellOnline">
					<div className="uk-container">
						<div className="stepMainTitle">4 Simple steps to start selling online</div>

						<div className="uk-grid uk-grid-column-medium uk-child-width-1-2@s">
							<div>
								<div className="sellingStepBox">
									<div className="stepBoxIcon">
										<img src="https://static.oxinis.com/seller/image/layout/list.png" />
									</div>
									<div className="setpBoxTitle">STEP 1: List Your Products</div>
									<p>
										Uploading your products is really simple through our self-serve tool. We also help you put together an
										attractive catalog by connecting you with industry experts.
									</p>
								</div>
							</div>
							<div>
								<div className="sellingStepBox">
									<div className="stepBoxIcon">
										<img src="https://static.oxinis.com/seller/image/layout/sell.png" />
									</div>
									<div className="setpBoxTitle">STEP 2: Sell Across India</div>
									<p>
										Maximise your online sales; attract more buyers and achieve higher conversion rates. Get orders and manage
										your online business via our top of the line Seller Panel.
									</p>
								</div>
							</div>

							<div>
								<div className="sellingStepBox">
									<div className="stepBoxIcon">
										<img src="https://static.oxinis.com/seller/image/layout/package-ship.png" />
									</div>
									<div className="setpBoxTitle">STEP 3: Ship with Ease</div>
									<p>
										Enjoy hassle-free pick-up and delivery across India through our logistics services and sell across the nation!
										Get packaging support & pan-india reach.
									</p>
								</div>
							</div>
							<div>
								<div className="sellingStepBox">
									<div className="stepBoxIcon">
										<img src="https://static.oxinis.com/seller/image/layout/earn.png" />
									</div>
									<div className="setpBoxTitle">STEP 4: Get Payments</div>
									<p>
										Receive quick and hassle-free payments in your account once your orders are fulfilled. Our payment process is
										fastest in the industry.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="vision">
					<div className="uk-container">
						<p>
							Healthmug’s vision is to create India’s most impactful digital commerce ecosystem that creates life-changing experiences
							for buyers and sellers. In April 2016, Anubhav Bansal along with Mohit Agarwal started Healthmug.com India’s largest
							online healthcare website, with the widest assortment of over 15000 homeopathic, ayurvedic, organic, orthopaedic, beauty
							and healthcare products. With lacs of users, Healthmug is the shopping destination for health products across the country,
							delivering to 8000+ cities and towns in India. Healthmug is the preferred choice of thousands of online shoppers with
							quick delivery even to the remotest corners of the country, discounts & offers to make products available at low prices to
							its valuable customers.{" "}
						</p>
						<p>
							In its endeavour to make online business easy, Healthmug has pioneered with a host of industry-first initiatives to help
							its sellers grow and prosper. Healthmug provides expert assistance through its Advisors who help sellers manage their
							online business. Sellers also get free training & support and all the information they need for carrying out their
							business. Healthmug also provides sellers with free inventory storage and faster, hassle free deliveries through the best
							courier service. Healthmug also helps sellers get in touch with professionals and experts for their various requirements
							such as Onboarding, Cataloguing, Photography, Business Consulting, etc.
						</p>
					</div>
				</div>
			</div>
			<footer>
				<div className="uk-container">
					<div className="uk-grid uk-grid-column-small uk-child-width-1-3@s">
						<div className="footerLinks">
							<h3>CONTACT US</h3>
							<p>
								E-Mail : <a href="mailto:mail@healthmug.com">mail@healthmug.com</a>
							</p>
						</div>
						<div className="footerLinks">
							<h3>STAY CONNECTED</h3>
							<div className="uk-flex uk-flex-center">
								<a href="https://www.facebook.com/healthmug/" className="socialIcon">
									<i uk-icon="icon:facebook"></i>
								</a>
								<a href="https://twitter.com/healthmug" className="socialIcon">
									<i uk-icon="icon:twitter" />
								</a>
							</div>
						</div>
						<div className="footerLinks copyright">
							<h3>
								Healthmug.<span>com</span>
							</h3>
							<div className="uk-text-center">
								In compliance with Drug and Cosmetic Act and Rules, we don't sell schedule H, H1, X or any habit forming drugs.
							</div>
							<p>Copyright© {new Date().getFullYear()}. All rights reserved.</p>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};

const mapStateToProps = (state) => ({
	email: state.email,
	password: state.password,
	error_list: state.error_list
});
const mapDispatchToProps = (dispatch) => ({
	Login_On_Focus: (field) => dispatch(LoginOnFocus(field)),
	Forgot_Password_Validation: (email) => dispatch(ForgotPasswordValidation(email)),
	Login_Check_Validation: () => dispatch(LoginCheckValidation()),
	Login_Set_Email_Or_Password: (field, value) => dispatch(LoginSetEmailOrPassword(field, value))
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
