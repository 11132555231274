import { LOGIN_SET_EMAIL_OR_PASSWORD, RESET_INITIAL_STATE, LOGIN_SET_ERROR_LIST } from "~/actions/action-types";

const __INITIAL_STATE__ = {
	email: "",
	password: "",
	error_list: {}
};

const LoginReducer = (state = __INITIAL_STATE__, action) => {
	let newState = Object.assign({}, state);
	if (action.type == LOGIN_SET_EMAIL_OR_PASSWORD) {
		if (action.field === "email") {
			newState.email = action.value;
		} else if (action.field === "password") {
			newState.password = action.value;
		}
		return newState;
	}
	if (action.type === LOGIN_SET_ERROR_LIST) {
		if (action.mode == "add") {
			newState.error_list = { ...newState.error_list, [action.field]: action.message };
		} else if (action.mode == "delete") {
			const new_error_list = newState.error_list;
			delete new_error_list[action.field];
			newState.error_list = { ...new_error_list };
		}
		return newState;
	}
	if (action.type == RESET_INITIAL_STATE) {
		return __INITIAL_STATE__;
	}
	return state;
};

export default LoginReducer;
