import thunk from "redux-thunk";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";

import UIkit from "uikit";
import "~/styles/general.scss";
import "flatpickr/dist/flatpickr.css";
import Icon from "uikit/dist/js/uikit-icons";

UIkit.use(Icon);

import LoginReducer from "~/reducers/login-reducer";

import LoginScreen from "~/screens/login/login-screen";

const store = createStore(LoginReducer, applyMiddleware(thunk));

ReactDOM.render(
	<Provider store={store}>
		<LoginScreen />
	</Provider>,
	document.getElementById("pageRoot")
);
